import React, { useState, useEffect } from "react";
import { message, Typography, Card, Col, Row } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import DemoColumn from "./tester_stats";
import useLogout from "../useLogout";

const duplicateMonthlyCount =
  "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/duplicate-otp-current-month";
const dailyOTPCount =
  "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/otp-today";
const monthlyOTPCount =
  "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/otp-current-month";
const previousMonthOTPCount =
  "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/otp-last-month";
const configSettingAPI = process.env.REACT_APP_ConfigSetting;

const Testers = () => {
  const { Title } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  const [dailyCount, setDailyCount] = useState(0);
  const [lowerLimitPayout, setLowerLimitPayout] = useState();
  const [bonusUSD, setBonusUSD] = useState();
  const [monthlyCount, setMonthlyCount] = useState(0);
  const [previousMonthCount, setPreviousMonthCount] = useState(0);
  const [duplicateMonthCount, setDuplicateMonthCount] = useState(0);

  const logout = useLogout();

  useEffect(() => {
    fetchOTPData();
  }, []);

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  const fetchOTPData = async () => {
    try {
      const userId = user.userId;

      // Fetch daily count
      const dailyResponse = await fetch(`${dailyOTPCount}/${userId}`);
      const dailyData = await dailyResponse.json();

      if (dailyData.inactive) {
        logout();
      } else {
        setDailyCount(dailyData.otp_today);
      }

      // Fetch previous month's count
      const previousMonthCountResponse = await fetch(
        `${previousMonthOTPCount}/${userId}`
      );
      const prevMonthData = await previousMonthCountResponse.json();
      if (prevMonthData.inactive) {
        logout();
      } else {
        setPreviousMonthCount(prevMonthData.otp_current_month);
      }

      // Fetch monthly count
      const monthlyResponse = await fetch(`${monthlyOTPCount}/${userId}`);
      const monthlyData = await monthlyResponse.json();
      if (monthlyData.inactive) {
        logout();
      } else {
        setMonthlyCount(monthlyData.otp_current_month);
      }

      const monthlyDuplicateResponse = await fetch(
        `${duplicateMonthlyCount}/${userId}`
      );
      const monthlyDuplicateData = await monthlyDuplicateResponse.json();
      if (monthlyDuplicateData.inactive) {
        logout();
      } else {
        setDuplicateMonthCount(
          monthlyDuplicateData.duplicate_otp_current_month
        );
      }

      const configSetting = await fetch(configSettingAPI);
      const bonusLimit = await configSetting.json();
      setBonusUSD(bonusLimit.body.bonus_limit);
      setLowerLimitPayout(bonusLimit.body.payout_lower_limit);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {contextHolder}
      <Title level={3}>Your Profile</Title>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={12} sm={8} md={8}>
          <Card
            size="small"
            title="Received Tests Today"
            style={{ color: "green", height: "10rem" }}
          >
            <p>
              <ArrowUpOutlined />
              OTP count for today: {dailyCount}
            </p>
          </Card>
        </Col>
        <Col xs={12} sm={8} md={8}>
          <Card
            size="small"
            title="Total Received Tests this Month"
            style={{ color: "green", height: "10rem" }}
          >
            <p>
              <ArrowUpOutlined />
              OTP count: {monthlyCount}
            </p>
          </Card>
        </Col>
        <Col xs={12} sm={8} md={8}>
          <Card
            size="small"
            title="Total Duplicate Tests this Month"
            style={{ color: "red", height: "10rem" }}
          >
            <p>
              <ArrowUpOutlined />
              OTP count: {duplicateMonthCount}
            </p>
          </Card>
        </Col>
        <Col xs={12} sm={8} md={8}>
          <Card
            size="small"
            title="Total Valid Tests this Month"
            style={{ color: "green", height: "10rem" }}
          >
            <p>
              <ArrowUpOutlined />
              OTP count: {monthlyCount - duplicateMonthCount}
            </p>
          </Card>
        </Col>
      </Row>

      <DemoColumn />
    </>
  );
};
export default Testers;
